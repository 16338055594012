import React from 'react';
import { Box, Grid, Hidden, Typography } from '@material-ui/core';
import styled from 'styled-components';

import { ContentContainer, ResponsiveImage } from '../../styles/styled';
import MapImage from '../../assets/images/contact-map.png';
import theme from '../../styles/themes';
import { FormattedHTMLMessage } from 'gatsby-plugin-intl';

const DimmedText = styled(Typography)`
  color: rgba(214, 214, 214, 0.3);
  font-weight: 500;
  transform: translateY(-50%);
  line-height: 117.19px;
  font-size: 60px;

  ${theme.breakpoints.up('lg')} {
    font-size: 100px;
  }
`;

const InfoContainer = styled(Box)`
  > div {
    padding: 60px 30px 30px 30px;
  }
  ${theme.breakpoints.up('md')} {
    max-width: 600px;
    > div {
      padding: 40px 40px 40px 80px;
    }
  }
  ${theme.breakpoints.up('lg')} {
    max-width: 722px;
    > div {
      padding: 65px 60px 60px 120px;
    }
  }
`;

const ImageContainer = styled(Box)`
  height: 300px;
  width: 85%;
  margin: auto auto -30px;
  border-radius: 16px;
  overflow: hidden;
  z-index: 1;
  ${theme.breakpoints.up('md')} {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 78%;
    width: 400px;
  }
  ${theme.breakpoints.up('lg')} {
    width: 540px;
  }
`;

const Info = () => {
  return (
    <Box position="relative">
      <ContentContainer>
        <Box position="relative">
          <Box position="relative" zIndex="1">
            <Typography style={{ fontWeight: '500' }} gutterBottom variant="h1">
              <FormattedHTMLMessage id="contactInfoTitle" />
            </Typography>
          </Box>
          <Hidden smDown>
            <Box position="absolute" top="0" left="0" zIndex="0">
              <DimmedText>
                <FormattedHTMLMessage id="contactInfoDrop" />
              </DimmedText>
            </Box>
          </Hidden>
        </Box>
        <Box position="relative" zIndex="1">
          <Grid container justify="flex-end">
            <ImageContainer>
              <ResponsiveImage
                style={{ backgroundImage: `url(${MapImage})` }}
              />
            </ImageContainer>
            <InfoContainer>
              <Box
                bgcolor="#1C1534"
                borderRadius="16px"
                boxShadow="0px 8px 6px rgba(0, 0, 0, 0.25)"
                color="#ffffff"
              >
                <Typography
                  variant="h3"
                  style={{ marginBottom: '60px', lineHeight: '28.13px' }}
                >
                  <FormattedHTMLMessage id="contactInfoContentTitle" />
                </Typography>
                <Typography
                  variant="body1"
                  style={{ marginBottom: '20px', lineHeight: '21.09px' }}
                >
                  <FormattedHTMLMessage id="contactInfoContentPhoneTitle" />
                </Typography>
                <Typography
                  variant="h3"
                  style={{ marginBottom: '60px', lineHeight: '28.13px' }}
                >
                  <FormattedHTMLMessage id="contactInfoContentPhoneText" />
                </Typography>
                <Typography
                  variant="body1"
                  style={{ marginBottom: '20px', lineHeight: '21.09px' }}
                >
                  <FormattedHTMLMessage id="contactInfoContentEmailTitle" />
                </Typography>
                <Typography
                  variant="h3"
                  style={{ marginBottom: '60px', lineHeight: '28.13px' }}
                >
                  <FormattedHTMLMessage id="contactInfoContentEmailText" />
                </Typography>
                <Typography
                  variant="body1"
                  style={{ marginBottom: '20px', lineHeight: '21.09px' }}
                >
                  <FormattedHTMLMessage id="contactInfoContentLocationTitle" />
                </Typography>
                <Typography variant="h3" style={{ lineHeight: '28.13px' }}>
                  <FormattedHTMLMessage id="contactInfoContentLocationText" />
                </Typography>
              </Box>
            </InfoContainer>
          </Grid>
        </Box>
      </ContentContainer>
      <Box
        bottom="0"
        width="100%"
        height="50%"
        bgcolor="#0A0C0E"
        position="absolute"
        zIndex="0"
      />
    </Box>
  );
};

export default Info;
