import React, { useState } from 'react';
import {
  Box,
  Grid,
  Select,
  Typography,
  Snackbar,
  MenuItem,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';

import WhiteArrow from '../../assets/icons/white-arrow.inline.svg';
import FormImage from '../../assets/images/contact-form.png';
import { ContentContainer, ResponsiveImage } from '../../styles/styled';
import theme from '../../styles/themes';
import { FormattedHTMLMessage, useIntl } from 'gatsby-plugin-intl';

const StyledSelect = styled(Select)`
  border-radius: 58px;
  margin-bottom: 55px;
  .MuiSelect-root {
    padding: 10px 28px 10px 18px;
    background-color: white;
    line-height: 22.09px;
    letter-spacing: 0.5px;
    font-size: 18px;
  }
  svg {
    color: #000000;
  }
  fieldset {
    border-color: #000000;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  color: #000000;
  &.Mui-checked {
    color: #000000;
  }
`;

const SubmitButton = styled(Button)`
  background: linear-gradient(
    97.31deg,
    #1a91ff 2.63%,
    #5c00f2 79.1%,
    #9e24fd 108.63%
  );
  box-shadow: 0px 3px 3px rgba(31, 0, 83, 0.4),
    -2px -3px 11px rgba(0, 111, 213, 0.1), 4px 3px 10px rgba(103, 33, 214, 0.3),
    inset 0px 1px 0px rgba(255, 255, 255, 0.7);
  border-radius: 51px;
  padding: 10px 15px;
  color: #ffffff;
`;
const Content = styled(ContentContainer)`
  ${theme.breakpoints.up('lg')} {
    padding: 60px 0 87px;
  }
`;

const TextGrid = styled(Grid)`
  width: 100%;
  margin-bottom: 40px;
  ${theme.breakpoints.up('md')} {
    width: 400px;
    margin-bottom: 0;
  }
  ${theme.breakpoints.up('lg')} {
    width: 480px;
  }
`;

const FormGrid = styled(Grid)`
  width: 100%;
  ${theme.breakpoints.up('md')} {
    width: 500px;
  }
  ${theme.breakpoints.up('lg')} {
    width: 590px;
  }
`;

const Form = () => {
  const { register, handleSubmit, formState, control } = useForm();
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [isErrorOpen, setIsErrorOpen] = useState(false);
  const intl = useIntl();

  const onSubmit = (data: any) => {
    data['form-name'] = 'contact';
    const formData = new FormData();
    Object.keys(data).forEach((k) => {
      formData.append(k, data[k]);
    });
    fetch('', {
      method: 'POST',
      // headers: {
      //   Accept: 'application/x-www-form-urlencoded',
      //   'Content-Type': 'application/x-www-form-urlencoded',
      // },
      body: formData,
    })
      .then(() => {
        setIsSuccessOpen(true);
      })
      .catch((error) => {
        setIsErrorOpen(true);
        console.log(error);
      });
  };

  return (
    <Box color="#ffffff">
      <Snackbar
        open={isSuccessOpen}
        autoHideDuration={3000}
        onClose={() => setIsSuccessOpen(false)}
      >
        <MuiAlert elevation={6} variant="filled" severity="success">
          Success
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={isErrorOpen}
        autoHideDuration={3000}
        onClose={() => setIsErrorOpen(false)}
      >
        <MuiAlert elevation={6} variant="filled" severity="error">
          Error
        </MuiAlert>
      </Snackbar>
      <ResponsiveImage style={{ backgroundImage: `url(${FormImage})` }}>
        <Box bgcolor="rgba(0, 0, 0, 0.65)">
          <Content>
            <Grid container alignItems="center" justify="space-between">
              <TextGrid item>
                <Typography
                  variant="h1"
                  style={{
                    fontWeight: '500',
                    marginBottom: '50px',
                    lineHeight: '50.23px',
                  }}
                >
                  <FormattedHTMLMessage id="contactFormLeftTitle" />
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    lineHeight: '21.09px',
                    letterSpacing: '1px',
                    marginBottom: '30px',
                  }}
                >
                  <FormattedHTMLMessage id="contactFormLeftText" />
                </Typography>
              </TextGrid>
              <FormGrid item>
                <Box
                  bgcolor="#ffffff"
                  borderRadius="16px"
                  boxShadow="0px 4px 14px rgba(0, 0, 0, 0.25)"
                  border="1px solid #000000"
                  padding="30px"
                  color="#000000"
                >
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    noValidate
                    netlify-honeypot="bot-field"
                    data-netlify="true"
                    name="contact"
                  >
                    <Controller
                      name="bot-field"
                      control={control}
                      defaultValue=""
                      render={({ onChange, value }) => (
                        <TextField
                          name="bot-field"
                          style={{ display: 'none' }}
                          id="bot-field"
                          hidden
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                    <Typography
                      variant="h3"
                      style={{ lineHeight: '26.21px', marginBottom: '50px' }}
                    >
                      <FormattedHTMLMessage id="contactFormTitle" />
                    </Typography>
                    <Typography variant="h6" style={{ marginBottom: '20px' }}>
                      <label id="position-label">
                        <FormattedHTMLMessage id="contactFormInterested" />
                      </label>
                    </Typography>
                    <Controller
                      control={control}
                      name="position"
                      defaultValue=""
                      rules={{
                        required: 'Choose',
                        validate: (value) => value !== '' || 'test',
                      }}
                      render={({ onChange, value, ref }) => (
                        <StyledSelect
                          name="position"
                          labelId="position-label"
                          id="position"
                          inputRef={ref}
                          error={formState.errors.position && true}
                          variant="outlined"
                          fullWidth
                          displayEmpty
                          onChange={onChange}
                          value={value}
                        >
                          <MenuItem value="" disabled>
                            <FormattedHTMLMessage id="contactFormChoose" />
                          </MenuItem>
                          <MenuItem value="Engineering and Development Services">
                            <FormattedHTMLMessage id="contactFormInterested1" />
                          </MenuItem>
                          <MenuItem value="Engineer">
                            <FormattedHTMLMessage id="contactFormInterested2" />
                          </MenuItem>
                          <MenuItem value="Services">
                            <FormattedHTMLMessage id="contactFormInterested3" />
                          </MenuItem>
                        </StyledSelect>
                      )}
                    />
                    <Typography variant="h6" style={{ marginBottom: '10px' }}>
                      <label id="specs-label">
                        <FormattedHTMLMessage id="contactFormSpec" />
                      </label>
                    </Typography>
                    <FormGroup style={{ marginBottom: '55px' }}>
                      <FormControlLabel
                        label={intl.formatHTMLMessage({
                          id: 'contactFormSpec1',
                        })}
                        control={
                          <StyledCheckbox
                            inputRef={register}
                            name="specPowertrain"
                            checkedIcon={<CheckBoxOutlinedIcon />}
                          />
                        }
                      />
                      <FormControlLabel
                        label={intl.formatHTMLMessage({
                          id: 'contactFormSpec2',
                        })}
                        control={
                          <StyledCheckbox
                            inputRef={register}
                            name="specTest"
                            checkedIcon={<CheckBoxOutlinedIcon />}
                          />
                        }
                      />
                      <FormControlLabel
                        label={intl.formatHTMLMessage({
                          id: 'contactFormSpec3',
                        })}
                        control={
                          <StyledCheckbox
                            inputRef={register}
                            name="specVehicle"
                            checkedIcon={<CheckBoxOutlinedIcon />}
                          />
                        }
                      />
                      <FormControlLabel
                        label={intl.formatHTMLMessage({
                          id: 'contactFormSpec4',
                        })}
                        control={
                          <StyledCheckbox
                            inputRef={register}
                            name="specOther"
                            checkedIcon={<CheckBoxOutlinedIcon />}
                          />
                        }
                      />
                    </FormGroup>
                    <Typography variant="h6" style={{ marginBottom: '20px' }}>
                      <label id="rep-label">
                        <FormattedHTMLMessage id="contactFormRep" />
                      </label>
                    </Typography>
                    <Controller
                      control={control}
                      name="rep"
                      defaultValue=""
                      rules={{
                        required: 'Choose',
                        validate: (value) => value !== '' || 'test',
                      }}
                      render={({ onChange, value, ref }) => (
                        <StyledSelect
                          name="rep"
                          labelId="rep-label"
                          id="rep"
                          inputRef={ref}
                          error={formState.errors.rep && true}
                          variant="outlined"
                          fullWidth
                          displayEmpty
                          onChange={onChange}
                          value={value}
                        >
                          <MenuItem value="" disabled>
                            <FormattedHTMLMessage id="contactFormChoose" />
                          </MenuItem>
                          <MenuItem value="Technology company">
                            <FormattedHTMLMessage id="contactFormRep1" />
                          </MenuItem>
                          <MenuItem value="Company">
                            <FormattedHTMLMessage id="contactFormRep2" />
                          </MenuItem>
                          <MenuItem value="Technology">
                            <FormattedHTMLMessage id="contactFormRep3" />
                          </MenuItem>
                        </StyledSelect>
                      )}
                    />
                    <Typography variant="h6" style={{ marginBottom: '10px' }}>
                      <label id="specs-label">
                        <FormattedHTMLMessage id="contactFormRole" />
                      </label>
                    </Typography>
                    <FormGroup row style={{ marginBottom: '50px' }}>
                      <FormControlLabel
                        label={intl.formatHTMLMessage({
                          id: 'contactFormRole1',
                        })}
                        control={
                          <StyledCheckbox
                            inputRef={register}
                            name="roleLead"
                            checkedIcon={<CheckBoxOutlinedIcon />}
                          />
                        }
                      />
                      <FormControlLabel
                        label={intl.formatHTMLMessage({
                          id: 'contactFormRole2',
                        })}
                        control={
                          <StyledCheckbox
                            inputRef={register}
                            name="roleSupport"
                            checkedIcon={<CheckBoxOutlinedIcon />}
                          />
                        }
                      />
                      <FormControlLabel
                        label={intl.formatHTMLMessage({
                          id: 'contactFormRole3',
                        })}
                        control={
                          <StyledCheckbox
                            inputRef={register}
                            name="roleAdvisory"
                            checkedIcon={<CheckBoxOutlinedIcon />}
                          />
                        }
                      />
                      <FormControlLabel
                        label={intl.formatHTMLMessage({
                          id: 'contactFormRole4',
                        })}
                        control={
                          <StyledCheckbox
                            inputRef={register}
                            name="roleOther"
                            checkedIcon={<CheckBoxOutlinedIcon />}
                          />
                        }
                      />
                    </FormGroup>
                    <Box display="flex" justifyContent="flex-end">
                      <SubmitButton type="submit">
                        <Typography
                          variant="body2"
                          style={{
                            fontWeight: '500',
                            lineHeight: '16.41px',
                            marginRight: '20px',
                          }}
                        >
                          <FormattedHTMLMessage id="contactFormButton" />
                        </Typography>
                        <WhiteArrow />
                      </SubmitButton>
                    </Box>
                  </form>
                </Box>
              </FormGrid>
            </Grid>
          </Content>
        </Box>
      </ResponsiveImage>
    </Box>
  );
};

export default Form;
