import React from 'react';

import SEO from '../components/SEO';
import Layout from '../layout/AppLayout';
import { Form, Info } from '../screens/Contact';

const Contact = () => {
  return (
    <Layout>
      <SEO title="Contact" />
      <Form />
      <Info />
    </Layout>
  );
};

export default Contact;
